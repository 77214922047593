import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer className="footer skin-dark-footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-4">
                        <div className="footer-widget">
                            <div className="d-flex align-items-start flex-column mb-3">
                                <Link to={"/"} className="nav-brand static-show">
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <img src="/assets/img/logo-light.png" className="logo" style={{ maxWidth: "60px", maxHeight: "40px" }} />
                                        <span style={{ marginLeft: "10px", fontSize:"25px" }}>Babaair.com</span>
                                    </div>
                                </Link>
                            </div>
                            <div className="footer-add pe-xl-3">
                                <p style={{ color: "#fff" }}>We make your dream more beautiful &amp; enjoyful with lots of happiness.</p>
                            </div>
                            <div className="foot-socials">
                                <ul>
                                    <li><Link to={"https://www.facebook.com/61568163874859"} target="_blank" style={{ color: "#fff" }}><i className="fa-brands fa-facebook" /></Link></li>
                                    <li><Link to={"https://twitter.com/babaairtravels"} target="_blank" style={{ color: "#fff" }}><i className="fa-brands fa-twitter" /></Link></li>
                                    <li><Link to={"https://instagram.com/babaair"} target="_blank" style={{ color: "#fff" }}><i className="fa-brands fa-instagram" /></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                        <div className="footer-widget" style={{ color: "#fff" }}>
                            <h4 className="widget-title">Search</h4>
                            <ul className="footer-menu">
                                <li><Link to={"/"} style={{ color: "#fff" }}>Home</Link></li>
                                <li><Link to={"/searchflights"} style={{ color: "#fff" }}>Flights</Link></li>
                                <li><Link to={"/searchhotels"} style={{ color: "#fff" }}>Hotels</Link></li>
                                <li><Link to={"/tours"} style={{ color: "#fff" }}>Holidays</Link></li>
                                <li><Link to={"/packages"} style={{ color: "#fff" }}>Umrah</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <div className="footer-widget">
                            <h4 className="widget-title">The Company</h4>
                            <ul className="footer-menu">
                                <li><Link to={"/about-us"} style={{ color: "#fff" }}>About Us</Link></li>
                                <li><Link to={"/terms"} style={{ color: "#fff" }}>Terms and Conditions</Link></li>
                                <li><Link to={"/faq"} style={{ color: "#fff" }}>FAQ's</Link></li>
                                <li><Link to={"/privacy-policy"} style={{ color: "#fff" }}>Privacy Policy</Link></li>
                                <li><Link to={"/refund"} style={{ color: "#fff" }}>Refund Policy</Link></li>
                                <li><Link to={"/contact-us"} style={{ color: "#fff" }}>Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-widget">
                            <h4 className="widget-title">Payment Methods</h4>
                            <div className="pmt-wrap">
                                <img src="/assets/img/payment.jpg" className="img-fluid" alt />
                            </div>
                            <div className="our-prtwrap mt-4">
                                <div className="prtn-title">
                                    <p className="text-light opacity-75 fw-medium">Our Partners</p>
                                </div>
                                <div className="prtn-thumbs d-flex align-items-center justify-content-start">
                                    <div className="pmt-wrap pe-4">
                                        <img src="/assets/img/razorpaypayment.png" style={{ maxWidth: "200px", maxHeight: "50px" }} className="img-fluid" alt />
                                    </div>
                                    <div className="pmt-wrap pe-4">
                                        <img src="/assets/img/iata.png" style={{ maxWidth: "200px", maxHeight: "50px" }} className="img-fluid" alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom border-top">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <p className="mb-0" style={{ color: "#fff" }}>© 2024 Baba Travels and Tourism.</p>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <ul className="p-0 d-flex justify-content-start justify-content-md-end text-start text-md-end m-0">
                                <li><Link to={"/terms"}>Terms of services</Link></li>
                                <li className="ms-3"><Link to={"/privacy-policy"}>Privacy Policies</Link></li>
                                <li className="ms-3"><Link to={"/faq"}>FAQ</Link></li>
                                <li className="ms-3"><Link to={"/refund"}>Refund</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}