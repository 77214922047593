import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AgentHeader from "../Layout/Header";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import PageLoader from "../Layout/PageLoader";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
const initialFieldValues = {
    umrahEnquiryId: "00000000-0000-0000-0000-000000000000",
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    umrahPriceId: "00000000-0000-0000-0000-000000000000",
    name: "",
    email: "",
    mobile: "",
    status: "NEW",
    adults: 0,
    kids: 0,
    infants: 0,
    comments: ""
};
export default function UmrahPackage() {
    const { state } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [tourDetail, setTourDetail] = useState({});
    const [tourInclusion, setTourInclusion] = useState([]);
    const [tourExclusion, setTourExclusion] = useState([]);
    const [tourPrices, setTourPrices] = useState([]);
    const [tourTerms, setTourTerms] = useState([]);
    const [tourFlights, setTourFlights] = useState([]);
    const [tourHotels, setTourHotels] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [status, setStatus] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const selectToDate = (nextdate) => {
        var todate = new Date();
        if (nextdate === undefined) {
            todate = addDays(fromDate)
        }
        else {
            todate = nextdate;
        }
        if (todate < fromDate) {
            alert("To Date should be greater than are equal to From Date")
            addDays(fromDate);
        }
        else {
            setFromDate(fromDate);
            setToDate(todate);
        }
    };
    const addDays = (cindate) => {
        console.log(cindate)
        console.log(toDate)
        if (cindate === undefined) {
            cindate = new Date();
        }
        const newDate = new Date(cindate.getTime());
        if (newDate > toDate) {
            newDate.setDate(cindate.getDate() + 1);
            setToDate(newDate);
        }
        else {
            setToDate(toDate)
        }
        setFromDate(cindate);
        return newDate;
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.name = values.name === "" ? false : true;
        temp.mobile = values.mobile === "" ? false : true;
        temp.umrahPriceId = values.umrahPriceId === "00000000-0000-0000-0000-000000000000" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("umrahEnquiryId", values.umrahEnquiryId)
            formData.append("umrahPackageId", values.umrahPackageId)
            formData.append("umrahPriceId", values.umrahPriceId)
            formData.append("name", values.name)
            formData.append("email", values.email)
            formData.append("mobile", values.mobile)
            formData.append("adults", values.adults)
            formData.append("infants", values.infants)
            formData.append("kids", values.kids)
            formData.append("comments", values.comments)
            formData.append("status", values.status)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) => axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATEUMRAHENQUIRY, newrecord, { ...headerconfig })
        };
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const addOrEdit = (formData) => {
        if (formData.get('umrahEnquiryId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        setStatus("Enquiry Submitted Successfully");
                        resetForm();
                        setBtnSubmit(true);
                    }
                    else {
                        setStatus("");
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    setStatus("");
                    setBtnSubmit(true);
                });
        }
    }
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const GetTourDetails = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETUMRAHBYID + "/" + params.umrahPackageId, { ...headerconfig })
            .then((response) => {
                setTourDetail(response.data.data);
                if (response.data.data != null) {
                    setTourInclusion(response.data.data.umrahInclusions);
                    setTourExclusion(response.data.data.umrahExclusions);
                    setTourPrices(response.data.data.umrahPrices);
                    setTourFlights(response.data.data.umrahFlights);
                    setTourHotels(response.data.data.umrahHotels);
                    setTourTerms(response.data.data.umrahTerms);
                }
                setLoading(false)
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetTourDetails();
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <AgentHeader></AgentHeader>
                    <div className="clearfix"></div>
                    <div className="py-2 gray-simple position-relative">
                        <div className="container">
                            {/* Search Form */}
                            <div className="row justify-content-center align-items-center">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">{tourDetail.umrahPackageName}</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="pt-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card border-0 p-3 mb-4">
                                        <div className="crd-heaader d-md-flex align-items-center justify-content-between">
                                            <div className="crd-heaader-first">
                                                <div className="d-block">
                                                    <h4 className="mb-0">{tourDetail.umrahPackageName}</h4>
                                                    <div className="exlops">
                                                        <p className="detail ellipsis-container fw-semibold">
                                                            <span className="ellipsis-item__normal">{tourDetail.totalDays} Days ({tourDetail.nightsInMakkah} Makkah & {tourDetail.nightsInMadina} Madina)</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="row">
                                        {/* Details */}
                                        <div className="col-xl-9 col-lg-9 col-md-12">
                                            <div className="mb-lg-0 mb-3">
                                                <div className="relative d-flex">
                                                    <img src={tourDetail.imageSrc} style={{ width: "100%", maxHeight: "400px" }} alt="image" className="rounded-2 img-fluid" />
                                                </div>
                                            </div>
                                            <div className="tab-content" id="pillstour-tabContent">
                                                {/* Overview Info */}
                                                <div className="tab-pane fade show active" id="pills-overview" role="tabpanel" aria-labelledby="pills-overview-tab" tabIndex={0}>
                                                    <div className="overview-wrap full-width">
                                                        <div className="card border rounded-3 mb-4">
                                                            <div className="card-header">
                                                                <h4 className="fs-5">Package Details</h4>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="expott-info mb-4">
                                                                    <h5>Flights</h5>
                                                                    <ul className="row align-items-center p-0 g-3">
                                                                        {tourFlights.length > 0 && tourFlights.map(t =>
                                                                            <li className="col-md-12">
                                                                                <i className="fa-regular fa-plane text-success me-2" />{t.airlineName}
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                                <div className="expott-info mb-4">
                                                                    <h5>Hotels</h5>
                                                                    <ul className="row align-items-center p-0 g-3">
                                                                        {tourHotels.length > 0 && tourHotels.map(t =>
                                                                            <li className="col-md-12">
                                                                                <i className="fa-regular fa-building text-success me-2" />{t.city} - {t.hotelName}
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                                <div className="expott-info mb-4">
                                                                    <h5>Terms</h5>
                                                                    <ul className="row align-items-center p-0 g-3">
                                                                        {tourTerms.length > 0 && tourTerms.map(t =>
                                                                            <li className="col-md-12">
                                                                                <i className="fa-regular fa-circle-dot text-success me-2" />{t.terms}
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                                <div className="expott-info mb-4">
                                                                    <h5>Inclusions</h5>
                                                                    <ul className="row align-items-center p-0 g-3">
                                                                        {tourInclusion.length > 0 && tourInclusion.map(t =>
                                                                            <li className="col-md-12">
                                                                                <i className="fa-regular fa-circle-dot text-success me-2" />{t.inclusion}
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                                <div className="expott-info mb-4">
                                                                    <h5>Exclusions</h5>
                                                                    <ul className="row align-items-center p-0 g-3">
                                                                        {tourExclusion.length > 0 && tourExclusion.map(t =>
                                                                            <li className="col-md-12">
                                                                                <i className="fa-regular fa-circle-dot text-danger me-2" />{t.exclusion}
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                                <div className="expott-info">
                                                                    <h5>Prices</h5>
                                                                    <table className="table table-responsive">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>From Date</th>
                                                                                <th>To Date</th>
                                                                                <th>Adult Price</th>
                                                                                <th>Kid Price</th>
                                                                                <th>Infant Price</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {tourPrices.length > 0 && tourPrices.map(t =>
                                                                                <tr>
                                                                                    <td>{moment(t.fromDate).format('DD MMM YYYY')}</td>
                                                                                    <td>{moment(t.toDate).format('DD MMM YYYY')}</td>
                                                                                    <td>{t.adultPrice}</td>
                                                                                    <td>{t.kidPrice}</td>
                                                                                    <td>{t.infantPrice}</td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Sidebar */}
                                        <div className="col-xl-3 col-lg-3 col-md-12">
                                            <div className="sides-block">
                                                <div className="card border rounded-3 mb-4">
                                                    <span className="badge bg-success p-6 full-width fw-medium text-uppercase mb-2">Please enter the following details</span>
                                                    <div className="single-card px-3 py-3">
                                                        {status !== "" ?
                                                            <div class="alert alert-info shadow-md p-3"> <i class="fas fa-check-circle"></i> {status} </div>
                                                            : ""}
                                                        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                                            <div className="form-floating mb-2">
                                                                <input type="text" maxLength={45} value={values.name} name="name" onChange={handleInputChange} className={"form-control" + applyErrorClass('name')} placeholder="Please enter customer name" />
                                                                <label>Name</label>
                                                                {errors.name === false ? (<div className="validationerror">Please enter customer name </div>) : ('')}
                                                            </div>
                                                            <div className="form-floating mb-2">
                                                                <input type="text" maxLength={45} value={values.email} name="email" onChange={handleInputChange} className={"form-control" + applyErrorClass('email')} placeholder="Please enter email" />
                                                                <label>Email</label>
                                                            </div>
                                                            <div className="form-floating mb-2">
                                                                <input type="text" maxLength={15} value={values.mobile} name="mobile" onChange={handleInputChange} className={"form-control" + applyErrorClass('mobile')} placeholder="Please enter mobile" />
                                                                <label>Phone</label>
                                                                {errors.mobile === false ? (<div className="validationerror">Please enter mobile </div>) : ('')}
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="form-floating mb-2">
                                                                        <input type="text" value={values.adults} name="adults" onChange={handleInputChange} className={"form-control" + applyErrorClass('adults')} placeholder="0" />
                                                                        <label>Adults</label>
                                                                        {errors.mobile === false ? (<div className="validationerror">Please enter adults </div>) : ('')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="form-floating mb-2">
                                                                        <input type="text" value={values.kids} name="kids" onChange={handleInputChange} className={"form-control" + applyErrorClass('kids')} placeholder="0" />
                                                                        <label>Kids</label>
                                                                        {errors.kids === false ? (<div className="validationerror">Please enter kids </div>) : ('')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-floating mb-2">
                                                                <select className="form-select" value={values.umrahPriceId} name="umrahPriceId" onChange={handleInputChange}>
                                                                    <option value="00000000-0000-0000-0000-000000000000">Please select dates</option>
                                                                    {tourPrices.length > 0 && tourPrices.map(t =>
                                                                        <option value={t.umrahPriceId}>{moment(t.fromDate).format('DD MMM YYYY')} - {moment(t.toDate).format('DD MMM YYYY')}</option>
                                                                    )}
                                                                </select>
                                                                {errors.umrahPriceId === false ? (<div className="validationerror">Please select dates </div>) : ('')}
                                                            </div>
                                                            <div className="form-floating mb-2">
                                                                <input type="text" value={values.comments} name="comments" onChange={handleInputChange} className={"form-control" + applyErrorClass('comments')} placeholder="Please enter comments" />
                                                                <label>Comments</label>
                                                                {errors.comments === false ? (<div className="validationerror">Please enter comments </div>) : ('')}
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                                    {
                                                                        btnSubmit === true ?
                                                                            <button type="submit" className="btn btn-primary w-100">Submit</button>
                                                                            :
                                                                            <button type="submit" className="btn btn-primary w-100" disabled>Please wait...</button>
                                                                    }
                                                                    <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </div>
            }
        </div>
    )
}