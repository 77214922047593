import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AgentHeader from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
export default function Terms() {
    return (
        <div id="main-wrapper">
            <div>
                <AgentHeader></AgentHeader>
                <div className="clearfix"></div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Terms</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="pt-3">
                    <div className="container">
                        <div className="bg-white shadow-md rounded p-4">
                            <div className="row mt-4 mb-2">
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"> <i className="far fa-thumbs-up" /> </div>
                                        <h5>Acceptance of Terms:</h5>
                                        <p>By accessing and using our website, you agree to comply with and be bound by these terms and conditions. If you do not agree with any part of these terms, please refrain from using our services.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"> <i className="far fa-thumbs-up" /></div>
                                        <h5>Booking and Payment:</h5>
                                        <p>All bookings are subject to availability and confirmation. Prices displayed on the website include applicable taxes and fees. Payment must be made in full at the time of booking, and we accept various secure payment methods.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"> <i className="far fa-thumbs-up" /> </div>
                                        <h5>Cancellation and Refund:</h5>
                                        <p>Cancellation policies vary by airline and holiday package. Please review the specific terms during the booking process. Refunds, if applicable, will be processed according to the cancellation policy and may be subject to fees.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"> <i className="far fa-thumbs-up" /> </div>
                                        <h5>Changes and Amendments:</h5>
                                        <p>Changes to bookings are subject to availability and the terms and conditions of the respective airline or holiday provider. Any additional costs resulting from changes will be the responsibility of the traveler.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"> <i className="far fa-thumbs-up" /> </div>
                                        <h5> Travel Insurance:</h5>
                                        <p>We recommend purchasing travel insurance to protect against unforeseen circumstances. The availability of travel insurance options will be presented during the booking process.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"> <i className="far fa-thumbs-up" /> </div>
                                        <h5>User Accounts:</h5>
                                        <p>Users are responsible for maintaining the confidentiality of their account information and password. Any activities conducted through the user's account are their responsibility. Notify us immediately of any unauthorized use of your account.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"> <i className="far fa-thumbs-up" /> </div>
                                        <h5>Privacy Policy:</h5>
                                        <p>Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our services, you consent to the terms of our Privacy Policy.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"> <i className="far fa-thumbs-up" /> </div>
                                        <h5>Limitation of Liability:</h5>
                                        <p>We are not liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of our website or services. This includes, but is not limited to, errors, omissions, interruptions, or delays.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"> <i className="far fa-thumbs-up" /> </div>
                                        <h5>Force Majeure:</h5>
                                        <p>We shall not be held liable for any failure or delay in performance resulting from acts beyond our control, including but not limited to natural disasters, government actions, or technological failures.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        </div>
    )
}