import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AgentHeader from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
export default function Contact() {
    return (
        <div id="main-wrapper">
            <div>
                <AgentHeader></AgentHeader>
                <div className="clearfix"></div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="pt-3">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-md-6">
                                <div className="bg-white shadow-md rounded h-100 p-3">
                                    <iframe className="h-100 w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.405485868966!2d78.47308937516505!3d17.392317683495683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9959b67d94dd%3A0x9da1d789abf4dc32!2sSS%20centre%20(Abids)!5e0!3m2!1sen!2sin!4v1727545560708!5m2!1sen!2sin" allowFullScreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bg-white shadow-md rounded p-4">
                                    <h4 className="text-6 mb-4">Get in touch</h4>
                                    <hr className="mx-n4 mb-4" />
                                    <p className="text-3">For Customer Support and Query, Get in touch with us:</p>
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"></div>
                                        <h5>BABA TRAVEL AND TOURISM</h5>
                                        <p>5-9-246/C, Shop No.1, SS Centre Building <br />
                                        Opp St, George's Grammar School,<br />
                                        Abids, Hyderabad,<br /> 
                                         Telangana 500001</p>
                                    </div>
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"></div>
                                        <h5>Telephone</h5>
                                        <p>(+91) 9177656344/ 9849787154</p>
                                    </div>
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"></div>
                                        <h5>Travel Inquiries</h5>
                                        <p>support@babaair.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        </div>
    )
}